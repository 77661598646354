@font-face {
    font-family: 'GTAmerica';
    font-style: normal;
    font-weight: normal;
    src: url('../fonts/GT-America-Standard-Regular.eot'); /* IE9 Compat Modes */
    src: url('../fonts/GT-America-Standard-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/GT-America-Standard-Regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/GT-America-Standard-Regular.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/GT-America-Standard-Regular.ttf')  format('truetype'); /* Safari, Android, iOS */
     font-display: block;
  }
  
  @font-face {
    font-family: 'GTAmerica';
    font-style: normal;
    font-weight: bold;
    src: url('../fonts/GT-America-Standard-Bold.eot'); /* IE9 Compat Modes */
    src: url('../fonts/GT-America-Standard-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/GT-America-Standard-Bold.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/GT-America-Standard-Bold.woff') format('woff'), /* Pretty Modern Browsers */
         url('../fonts/GT-America-Standard-Bold.ttf')  format('truetype'); /* Safari, Android, iOS */
     font-display: block;
  }